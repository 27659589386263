import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="60"
    height="85"
    viewBox="0 0 97 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_109_64)">
      <path
        d="M62.6978 88.9339L33.0777 1.29893C32.9373 0.855437 32.8116 0.423079 32.6989 0H0.00585938L0.395744 1.0911L49.1443 138L49.3845 137.993L97.0059 0H92.3291C92.2164 0.358133 62.6978 88.9339 62.6978 88.9339Z"
        fill="black"
      />
      <path
        d="M60.1202 92.48L48.5567 126.746L4.67712 2.94857L29.7628 2.80383L60.1202 92.48Z"
        fill="white"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M39.0369 101.287L48.08 91.5318L42.5403 82.2611L50.9496 65.6293L60.1165 92.3464L48.08 91.5318L60.1165 92.3464L42.2539 110.815"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M28.0092 27.9845L29.6925 2.77786L37.141 25.5685L36.7307 50.365L36.7178 50.8642L43.4272 43.2116"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M15.5385 35.1768L36.7179 50.8642L28.0092 27.9845L8.45032 16.5038L15.5385 35.1768Z"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M36.7178 50.8642L50.992 65.4196L42.5402 82.2611L36.7178 50.8642ZM36.7178 50.8642L27.0114 67.5331L33.4639 73.5268L40.1954 70.4279"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M48.08 91.5318L42.5403 82.2611L50.9921 65.4196L60.1165 92.3464L48.08 91.5318ZM48.08 91.5318L60.1165 92.3464"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M33.6321 77.5757L42.5403 82.2611L35.4466 90.09"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M50.992 65.4196L48.0633 91.5373L60.0998 92.3519L42.2372 110.819"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M42.8618 110.281L54.5565 108.798L51.552 117.178L45.4635 117.92L42.8618 110.281Z"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M44.0018 96.1114L45.2842 100.75L51.1861 101.457"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M42.8618 110.281L43.102 102.617L45.2843 100.75"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M43.102 102.617L39.702 101.007"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M31.9746 80.0437L33.6321 77.5757L33.4639 73.5268"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M48.0634 91.5373L53.1928 85.1652L57.9084 85.5233"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M53.1927 85.1652L54.6932 75.6923"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M54.5565 108.798L53.98 103.248L48.335 104.397"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M53.98 103.248L55.4804 99.5165H58.1227"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
      <path
        d="M55.4803 99.5165L59.5529 93.0589"
        stroke="black"
        strokeWidth="0.302996"
        strokeLinejoin="bevel"
      />
    </g>
    <defs>
      <clipPath id="clip0_109_64">
        <rect
          width="97"
          height="138"
          fill="white"
          transform="translate(0.00585938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
