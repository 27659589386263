import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="140"
    height="191"
    viewBox="0 0 140 191"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91.8458 122.71L51.2959 3.22998C51.0959 2.61998 50.9259 2.02996 50.7759 1.45996H6.00586L6.53589 2.93994L73.2859 189.61H73.6158L138.826 1.46997H132.416C132.266 1.94997 91.8458 122.72 91.8458 122.72V122.71Z"
      stroke="#333333"
      strokeWidth="1.85"
      strokeLinejoin="bevel"
    />
    <g clipPath="url(#clip0_186_2)">
      <path
        d="M68.7004 129.217L54.4368 171.123L0.311234 19.727L31.2545 19.5522L68.7004 129.217Z"
        fill="white"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M43.1932 139.463L53.8487 128.057L47.0155 116.722L57.3884 96.3828L68.6958 129.056L53.8487 128.057L68.6958 129.056L47.517 150.863"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M29.0914 50.3462L31.1656 19.5205L40.3555 47.3894L39.8495 77.7158L39.8336 78.324L48.1096 68.9678"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M14.5315 59.2577L39.8336 78.324L29.0914 50.3463L6.30795 36.5514L14.5315 59.2577Z"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M39.8336 78.324L57.4409 96.1241L47.0155 116.722L39.8336 78.324ZM39.8336 78.324L28.6356 98.7338L35.8198 106.039L44.1209 102.251"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M53.8487 128.057L47.0155 116.722L57.3383 96.1196L68.6958 129.056L53.8487 128.057ZM53.8487 128.057L68.6958 129.056"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M36.0272 110.99L47.0155 116.722L38.2655 126.294"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M57.4067 96.2172L53.8282 128.064L68.6753 129.06L47.517 150.863"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M29.0914 50.3462L14.6386 60.1698"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M47.4098 150.986L61.8353 149.175L58.1292 159.421L50.619 160.328L47.4098 150.986Z"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M58.0084 159.698L52.8436 165.993L55.0568 165.255L56.1371 166.48"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M48.8161 133.658L50.4002 139.331L57.6802 140.198"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M47.4098 150.986L47.7084 141.616L50.4002 139.331"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M47.7084 141.616L43.3186 139.531"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M33.9827 114.008L36.0272 110.99L35.8198 106.039"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M53.8282 128.064L60.1554 120.271L65.9721 120.712"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M60.1554 120.271L61.8854 109.188"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M61.8353 149.175L61.1241 142.385L54.4505 143.717"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M61.1241 142.385L62.9749 137.822L65.8468 137.872"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M62.9749 137.822L68.0007 129.925"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
      <path
        d="M55.0567 165.255L57.1924 163.052"
        stroke="#333333"
        strokeWidth="0.369871"
        strokeLinejoin="bevel"
      />
    </g>
    <defs>
      <clipPath id="clip0_186_2">
        <rect
          width="69"
          height="152"
          fill="white"
          transform="translate(0.00585938 19.23)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
