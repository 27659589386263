import React, { useState } from "react";
import { Cross } from "../assets/svg";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { categoryOptions } from "../constants/constants";
import Select, { ActionMeta, MultiValue } from "react-select";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface FormData {
  category: MultiValue<{ value: string; label: string }>;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  details: string;
}

const Form: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const initialFormState: FormData = {
    category: [],
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    details: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  // Handle change for text inputs and text areas
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle change for category select input
  const handleCategoryChange = (
    selectedOptions: MultiValue<{ value: string; label: string }>,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => {
    setFormData({
      ...formData,
      category: selectedOptions,
    });
  };

  // Handle change for phone input
  const handlePhoneChange = (value: string) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formDataToSend: Record<string, unknown> = {
      ...formData,
      category: formData.category.map((option) => option.value),
    };

    emailjs
      .send(
        "service_0wpqbw7", // Replace with your Service ID
        "template_trlrcwj", // Replace with your Template ID
        formDataToSend,
        "8roJNM7Wc27KD8BKS" // Replace with your User ID
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        toast.success("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        toast.error("Submission failed. Please try again later.");
      });
    setFormData(initialFormState);
    onClose();
  };

  const handleCloseModal = (e: React.MouseEvent) => {
    setFormData(initialFormState);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      tabIndex={-1}
      aria-hidden={!isOpen}
      className={`fixed top-0 right-0 left-0 z-50 flex justify-center items-center bg-black bg-opacity-50 w-full md:inset-0 h-full max-h-full ${
        isOpen ? "flex" : "hidden"
      }`}
    >
      <div className="relative w-full md:w-1/2 xl:w-[600px] mx-4 h-4/5 md:h-auto overflow-y-auto">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <div className="w-full flex justify-center items-center">
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-sans">
                <span className="font-bold">Contact Us</span> Now
              </h2>
            </div>
            <button
              type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-[#e5e7eb] hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={handleCloseModal}
            >
              <Cross />
            </button>
          </div>
          <div className="p-4 md:p-5">
            <form onSubmit={handleSubmit} className="py-5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 mb-2 font-sans"
                  >
                    First Name:
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 font-sans"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-gray-700 mb-2 font-sans"
                  >
                    Last Name:
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 font-sans"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-sans mb-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 font-sans"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-sans mb-2"
                >
                  Phone Number:
                </label>
                <PhoneInput
                  country={"us"}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: "100%",
                    borderRadius: "0.375rem",
                    padding: "0.5rem",
                    paddingLeft: "3.5rem",
                    fontSize: "1rem",
                    height: "2.5rem",
                  }}
                  containerStyle={{
                    width: "100%",
                  }}
                  inputClass="focus:outline-none focus:ring focus:border-blue-300 font-sans"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-sans mb-2">
                  Category:
                </label>
                <Select
                  isMulti
                  options={categoryOptions}
                  value={formData.category}
                  onChange={handleCategoryChange}
                  className="w-full font-sans"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="details"
                  className="block text-gray-700 mb-2 font-sans"
                >
                  Details:
                </label>
                <textarea
                  id="details"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="w-full h-[180px] px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 font-sans"
                  required
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-24 text-black py-2 px-4 border border-black rounded-md hover:bg-blue-600 hover:text-white"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
