import { Pattern } from "../assets/svg";

const PatternSection = () => (
  <>
    <div className="w-full">
      <Pattern />
    </div>
    <div className="pt-4 py-6 md:pt-6 md:py-8 italic">
      <p className="text-md md:text-xl lg:text-2xl tracking-wide italic">
        Fig. 1/n: Our world as viewed by Voronoi
      </p>
    </div>
  </>
);

export default PatternSection;
