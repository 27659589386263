import { useState, useEffect } from "react";
import Form from "../components/Form";
import Header from "../components/Header";
import PatternSection from "../components/PatternSection";
import AboutSection from "../components/AboutSection";
import ResearchSection from "../components/ResearchSection";
import ResourcesSection from "../components/ResourcesSection";

function Home() {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("overflow-y-hidden");
    };
  }, [isModalOpen]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="container my-6 md:my-12 lg:my-24">
      <Header openModal={openModal} />
      <hr className="border border-black" />
      <div className="grid md:grid-cols-1 lg:grid-cols-8 w-full">
        {/* The left column */}
        <div className="md:col-span-0 lg:col-span-1"></div>
        {/* The middle column */}
        <div className="md:col-span-1 lg:col-span-6">
          <div className="mt-4 mx-4 md:mt-8 md:mx-8 lg:mt-14 lg:mx-0">
            <PatternSection />
            <hr className="border border-black" />
            <AboutSection />
            <hr className="border border-black" />
            <ResearchSection openModal={openModal} />
            <hr className="border border-black" />
            <ResourcesSection />
          </div>
        </div>
        {/* The right column */}
        <div className="md:col-span-0 lg:col-span-1"></div>
      </div>
      <Form isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default Home;
