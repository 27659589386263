import React from "react";
import { Logo } from "../assets/svg";
import { LogoTablet } from "../assets/svg";
import { LogoMobile } from "../assets/svg";
import useWindowSize from "../hooks/useWindowSize";

interface HeaderProps {
  openModal: () => void;
}

const Header: React.FC<HeaderProps> = ({ openModal }) => {
  const { width } = useWindowSize();
  let LogoComponent;

  if (width >= 1140) {
    LogoComponent = Logo;
  } else if (width >= 768) {
    LogoComponent = LogoTablet;
  } else {
    LogoComponent = LogoMobile;
  }

  return (
    <>
      <div className="flex h-20 md:h-28 lg:h-[168px] border-t-2 border-b-2 border-black">
        <div className="grid grid-cols-6 lg:grid-cols-8 w-full">
          <div className="col-span-1 lg:col-span-1 my-2">
            <div className="flex justify-center items-center h-full">
              <LogoComponent />
            </div>
          </div>
          <div className="col-span-4 lg:col-span-6 my-2 border-black border-x-2">
            <div className="h-full flex items-center ml-5 lg:ml-16">
              <h1 className="text-2xl md:text-4xl lg:text-[50px] text-black font-bold tracking-wide">
                voronoi trading
              </h1>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-1 my-2">
            <div className="h-full flex justify-center">
              <button
                onClick={openModal}
                className="text-lg md:text-2xl lg:text-3xl text-black font-bold cursor-pointer tracking-tight hover:underline"
              >
                contact
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[44px] flex justify-center items-center">
        <p className="text-md md:text-lg lg:text-xl text-black font-bold tracking-wider">
          a crypto quant fund
        </p>
      </div>
    </>
  );
};

export default Header;
