const ResourcesSection = () => (
  <div className="mt-4 md:mt-8 flex flex-col md:flex-row md:mx-0">
    <div className="w-full md:w-1/2 inline-grid">
      <div className="mt-0">
        <p className="text-black text-2xl lg:text-3xl font-bold tracking-wide">
          resources
        </p>
      </div>
      <ul className="list-none mt-3 md:mt-6">
        <li className="mb-3 lg:mb-5">
          <div className="flex items-center">
            <span className="mr-2 inline-block bg-black w-2 h-2 rounded-full"></span>
            <a
              className="underline text-xl lg:text-[25px]"
              href="https://twitter.com/0x_illuminati"
              target="_blank"
              rel="noreferrer"
            >
              Degen Research Collective
            </a>
          </div>
        </li>
        <li className="mb-3 lg:mb-5">
          <div className="flex items-center">
            <span className="mr-2 inline-block bg-black w-2 h-2 rounded-full"></span>
            <a
              className="underline text-xl lg:text-[25px]"
              href="https://twitter.com/0x_illuminati"
              target="_blank"
              rel="noreferrer"
            >
              JOB Board
            </a>
          </div>
        </li>
        <li className="mb-3 lg:mb-5">
          <div className="flex items-center">
            <span className="mr-2 inline-block bg-black w-2 h-2 rounded-full"></span>
            <a
              className="underline text-xl lg:text-[25px]"
              href="https://twitter.com/0xkitsuneko"
              target="_blank"
              rel="noreferrer"
            >
              Japanese Research
            </a>
          </div>
        </li>
        <li className="mb-3 lg:mb-5">
          <div className="flex items-center">
            <span className="mr-2 inline-block bg-black w-2 h-2 rounded-full"></span>
            <a
              className="underline text-xl lg:text-[25px]"
              href="https://t.me/m6labs"
              target="_blank"
              rel="noreferrer"
            >
              M6Labs Daily Alpha
            </a>
          </div>
        </li>
        <li className="mb-3 lg:mb-5">
          <div className="flex items-center">
            <span className="mr-2 inline-block bg-black w-2 h-2 rounded-full"></span>
            <a
              className="underline text-xl lg:text-[25px]"
              href="https://t.me/M6_TheLab"
              target="_blank"
              rel="noreferrer"
            >
              M6Labs Research Community
            </a>
          </div>
        </li>
        <li className="mb-3 lg:mb-5">
          <div className="flex items-center">
            <span className="mr-2 inline-block bg-black w-2 h-2 rounded-full"></span>
            <a
              className="underline text-xl lg:text-[25px]"
              href="https://cryptopragmatist.com/"
              target="_blank"
              rel="noreferrer"
            >
              Crypto Pragmatist
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div className="w-full md:w-1/2 flex items-end justify-center md:justify-end mx-0 md:my-5">
      <div className="text-xs tracking-wide">
        &#169; 2024 COPYRIGHT VORONOI FUND
      </div>
    </div>
  </div>
);

export default ResourcesSection;
