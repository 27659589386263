const AboutSection = () => (
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-0 md:gap-y-4 gap-x-0 md:gap-x-8 mx-0">
    <div className="col-span-1">
      <div className="mt-4 md:mt-8">
        <p className="text-2xl lg:text-3xl text-black font-bold tracking-normal">
          what is voronoi trading
        </p>
      </div>
    </div>
    <div className="col-span-1"></div>
    <div className="col-span-1">
      <div className="pt-0">
        <div className="mt-3">
          <p className="text-lg lg:text-xl text-justify leading-5">
            We are proud to be a pioneering investment fund that combines
            mathematical intelligence and advanced technology to create
            groundbreaking investment strategies. We focus not only on
            optimizing returns but also on ensuring the safety and
            sustainability of your investment portfolio.
          </p>
        </div>
      </div>
    </div>
    <div className="col-span-1">
      <div className="pt-0 pb-6 md:pb-12">
        <div className="mt-3">
          <p className="text-lg lg:text-xl text-justify leading-5">
            Quantum Trading, one of the methods we employ, leverages complex
            mathematical principles and models to maximize returns and minimize
            risks. By applying modern technology and big data analytics, Quantum
            Trading enables us to identify potential trading opportunities with
            high precision, perfectly integrating mathematics, data science, and
            information technology.
          </p>
        </div>
        <div className="text-black text-3xl py-0 md:py-2 font-normal"></div>
        <div className="mt-3">
          <p className="text-lg lg:text-xl text-justify leading-5">
            We operate as a Market Maker, ensuring market liquidity by
            continuously providing buy and sell quotes for financial assets.
            This role not only facilitates smoother and more efficient trading
            but also helps stabilize prices and enhance market confidence.
            Through our Market Maker activities, we guarantee that your
            transactions are executed swiftly and at the best possible prices.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default AboutSection;
