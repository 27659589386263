import { ResearchLogo } from "../assets/svg";

interface ResearchSectionProps {
  openModal: () => void;
}

const ResearchSection: React.FC<ResearchSectionProps> = ({ openModal }) => (
  <div className="mt-4 md:mt-8 mb-8 md:mb-16 xl:mx-0">
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div className="mt-0">
        <p className="text-black text-2xl lg:text-3xl font-bold tracking-wide">
          research
        </p>
        <div className="mt-3">
          <button onClick={openModal}>
            <p className="text-xl lg:text-[25px] underline">contact us</p>
          </button>
        </div>
      </div>
      <div className="mt-0 md:mt-11 flex justify-end lg:justify-end">
        <div className="border border-black w-[376px] h-[256px] md:h-[296px] flex flex-col justify-between">
          <div className="mt-6 flex justify-center">
            <ResearchLogo />
          </div>
          <div className="flex self-start ml-3 mb-2">
            <p className="text-md md:text-xl lg:text-2xl tracking-wide italic">
              Fig. 2/n: Deconstruction
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ResearchSection;
